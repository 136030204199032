import React from 'react'
import './newsletter.css';

export default class Newsletter extends React.Component{
  constructor(props){
    super(props);

    this.handleFieldChange = (event) => {
      event.persist();
      let itemName = event.target.name;
      let itemValue = event.target.value;
      this.setState( () => ( { [itemName]:itemValue } ));
    }

    this.handleSubmit = (event) => {
      const formData = new FormData();
      formData.append('nome',this.state.name);
      formData.append('email',this.state.email);
      console.log(this.state);
    }

    this.state = {
      name:'',
      email:''
    }
  }

  render(){
    return(
      <React.Fragment>
        <div className="card" id="newsletter">
          <form>
            <div className="card-body">
              <div className="card-title">
                <h2>RECEBA NOTÍCIAS DA TEIA</h2>
              </div>
              <div className="form-group">
                <div className="col">
                  <p>Inscreva-se na nossa newsletter<br/> e acompanhe nosso trabalho.</p>
                </div>
              </div>
              <div className="form-group">
                <input type="text" name="email" id="newsletter-email" className="form-control form-control-lg" placeholder="NOME" onChange={this.handleFieldChange}/>
              </div>
              <div className="form-group">
                <input type="text" name="name" id="newsletter-name" className="form-control form-control-lg" placeholder="E-MAIL" onChange={this.handleFieldChange}/>
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-success btn-lg" onClick={this.handleSubmit}>ASSINAR</button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}
