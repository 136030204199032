import React from 'react'
import Layout from '../Layout';
import Divider from '../Divider';
import { Carousel } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import {ShareButtons, DonationButtons} from '../SocialNetwork/ShareButtons';
import Newsletter from './Newsletter';
import Newspanel from './Newspanel';

const duration = .5

const variants = {
  initial: {
    opacity: 1,
  },
  enter: {
        opacity: 1,
        transition: 
        {
            duration: duration,
            delay: duration,
            when: 'beforeChildren',
        },
    },
    exit: {
        opacity: 0,
    },
}

const container = {
    initial: {
        opacity: 1,
    },
    enter: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
        },
    },
    pageWorkAnimate:{
        
    }
};
const animatedItem = {
  initial: { y: 20, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
  },
};

export const NewsChild = ({data,news}) => {
  const url = (typeof window !== 'undefined') ? location.href : null;
  return (
    <React.Fragment>
      <div id="page-news-item">
        <div className="container-fluid">
          <BackgroundImage
            Tag="section"
            fluid={data.image ? data.image.childImageSharp.fluid : ''}
            backgroundColor={`#040e18`}>
              <div className="container">
                <div className="row m-0 h-100">
                  <div className="col-md-12 news-title text-center">
                    <div className="p-2">
                      <p>{data.title}</p>
                    </div>
                    <div className="p-2 text-center news-author">
                      <p>{data.created_by !== null ? `por ${data.created_by.firstname}${data.created_by.lastname}` : ""}</p>
                      <p>{data.published_at}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay-news-item"></div>
          </BackgroundImage>
        
          <div className="row m-0 p-0">
            <div className="col-md-12 text-center p-3">
              <ShareButtons title={data.title} url={url} twitterHandle="" tags="abc"/>
            </div>
          </div> 
        </div>
        
        <div className="d-flex row m-0">
          <div className="d-flex col-lg-2 justify-content-center order-2 order-sm-2 order-md-3 order-lg-1 order-xl-1 p-3">
            <DonationButtons title={data.title} url={url} twitterHandle="" tags="abc"/>
          </div>

          <div className="d-flex m-0 col-md-8 col-lg-6 order-sm-1 order-md-1 order-lg-2 order-xl-2">
            <div className="container">
            <article>
              <div dangerouslySetInnerHTML= {{ __html: data.content}} className="ck-content"></div>
            </article>
            </div>
          </div>
        
          <div className="d-flex order-3 order-sm-3 order-md-2 order-lg-2 order-xl-2 justify-content-center col-md-4 col-lg-3 ml-0 p-3">
            <div className="d-flex flex-column mr-0">
              <div className="p-2">
                <Newsletter/>
              </div>
              <div className="p-2">
                <Newspanel news={news}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

class NewsItem extends React.Component {

  constructor(props){
    super(props);
    this.state = {data:this.props.data.strapiArticles, news:this.props.data.allStrapiArticles.edges};
  }

  render(){
    const {data,news} = this.state;
    return(
      <React.Fragment>        
        <motion.main
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit">
          <NewsChild data={data} news={news}/>
        </motion.main>
      </React.Fragment>
    );
  }
}

export default NewsItem

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticles(id: { eq: $id }) {
      title
      content
      url
      category{
        id
        description
      }
      created_by{
        username,
        firstname,
        lastname
      }
      image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      published_at(formatString: "DD/MM/Y HH:ss")
      
    }
    allStrapiArticles(limit:5) {
      edges {
        node {
          id
          title
          url
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }`