import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image'
import {TransitionLink} from '../Transition/TransitionLink'
import './newspanel.css'

class Newspanel extends React.Component{

    constructor(props){
        super(props);
        this.state = { news: this.props.news }
    }

    render(){
        let {news} = this.state;
        return(
            <React.Fragment>         
                <div className="card" id="newspanel">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item text-center">
                        <h2>RELACIONADAS</h2>
                    </li>
                    {news.map( item=> (
                        <li className="list-group-item newspanel-item" key={item.node.id} >
                            <div className="row">
                                <div className="col-md-6 newspanel-limit-text">
                                    <TransitionLink to={`/${item.node.url}`} >
                                        <h3>{item.node.title}</h3>
                                    </TransitionLink>
                                </div>
                                <div className="col-md-6 ">
                                    <TransitionLink to={`/${item.node.url}`}>
                                        <Img fluid={item.node.image ? item.node.image.childImageSharp.fluid : ''} alt={item.node.title} />
                                    </TransitionLink>
                                </div>
                            </div>
                        </li>
                    ))}
                  </ul>
                </div> 
            </React.Fragment>
        );
    }
} 

export default Newspanel;
